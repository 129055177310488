/* src/index.css */
body, html {
  height: 100%;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  background-color: BLACK;
  align-items: center;
}

.app-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85vh;
  background-color: BLACK;
  align-items: center;
}
