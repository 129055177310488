/* src/Loading.css */
.loading-containerQ {
    font-size: 45px;
    font-weight: bold;
    color: #0092FF;
    align-items: center;
    font-family: system-ui;
  }
  .loading-containerQLaunch {
    font-size: 40px;
    font-weight: bold;
    color: #FFF;
    align-items: center;
    font-family: system-ui;
  }

  .cursor {
    display: inline-block;
    align-items: self-end;
    height: 5.5vh;
    margin-bottom: 4%;
    font-size: 60px;
    width: 5px;
    background-color: transparent;
    animation: blink 1s infinite;
    color: #FFF;
    font-family: system-ui;
  }

  .wrap-text {
    display: flex;
    text-wrap: wrap;
    align-items: self-end;
    /* font-size: 60px; */
    
  }
  
  @keyframes blink {
    0%, 50% {
      background-color: transparent;
    }
    50.01%, 100% {
      background-color: rgb(255, 255, 255);
    }
  }
  